import React from 'react';
import { graphql } from 'gatsby';
import { globalHistory } from '@reach/router';
import Scroll, { Element } from 'react-scroll';
import PageHeader from '../components/PageHeader/PageHeader';
import PageTemplate from '../components/PageTemplate/PageTemplate';
import { PageComponent } from '../types/gatsby.types';
import { WpPageContent } from '../types/wordpress.types';
import joinClassNames from '../utils/className.utils';
import { sanitize } from '../utils/sanitize.utils';
import './page-content-page.scss';
import { useOnMount } from '../hooks/lifecycle.hooks';
import { isBrowser } from '../env';
import tick from '../utils/waiters.utils';
import BaseLineSeparator from '../components/BaseLineSeparator/BaseLineSeparator';
import PageSectionContentOnImage from '../components/PageSectionContentOnImage/PageSectionContentOnImage';
import BaseLink from '../components/BaseLink/BaseLink';
import BaseImage from '../components/BaseImage/BaseImage';

export const query = graphql`
  query ($id: String) {
    wpPage(id: { eq: $id }) {
      ...PageWithContentFragment
    }
  }
`;

type PageContentPageProps = {
  data: any,
}

const hashScrollPrefix = "scroll-to-";

const PageContentPage: PageComponent<PageContentPageProps> = (props) => {
  const {
    data: {
      wpPage: { id, title, content, slug, isFrontPage, featuredImage, seo, contentPage },
    },
  } = props;
  const pageContent = contentPage as WpPageContent;

  useOnMount(() => {
    const handleHashChange = async () => {
      const hash = window.location.hash.replace('#', '');
      const targetClassName = `${hashScrollPrefix}${hash}`;
      await tick(500);
      if (document.querySelector(`#${targetClassName}`)) {
        Scroll.scroller.scrollTo(targetClassName, {
          smooth: true,
          duration: 500,
          delay: 0,
          offset: isBrowser ? -(window.innerHeight / 4) : -300,
        });
      }
    };
    handleHashChange();
    const historySubscriber = globalHistory.listen(handleHashChange);
    return () => {
      historySubscriber();
    }
  })

  return (
    <PageTemplate
      {...props} // always include
      wpYoastSEO={seo}
      className={joinClassNames(slug, 'PageContentPage')}
      pageSlug={slug}
      pageTemplate='page'
      title={title}
      isFrontPage={isFrontPage}
      featuredMedia={featuredImage?.node}
    >

      <PageHeader title={title} hasImage={featuredImage?.node} />

      <section className="PageContentPageContentContainer PageSection">
        <div className="container">

          <header className="PageContentPage_Header">
            <div>
              <h2 className="PageContentPage_Header_Heading">{pageContent.headerHeading}</h2>
              <p className="PageContentPage_Header_Subheading">{pageContent.headerSubheading}</p>
            </div>
            {pageContent.headingSidenote && <div className="PageContentPage_Header_SiteNote" dangerouslySetInnerHTML={{ __html: sanitize(pageContent.headingSidenote) }} />}
          </header>

          <main className="PageContentPage_Main">
            <div className="PageContentPage_MainFirstContentSection" dangerouslySetInnerHTML={{ __html: sanitize(pageContent.mainFirstContentSection) }} />

            <div className="PageContentPage_MainFirstContentFeaturedProductsOrdering">
              {pageContent.mainFirstContentFeaturedProductsOrdering?.map((obj, order) => {
                return <div key={order} className={`PageContentPage_MainFirstContentFeaturedProducts`}>
                  {obj.featuredProducts?.map((prod) => {
                    const orderClass = `Order${order}`;
                    const img = prod.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData
                    return <BaseLink
                      key={prod.id}
                      href={prod.productFields?.actionLink?.url}
                      title={prod.productFields?.actionLink?.title}
                      externalInNewTab
                    >
                      {img
                        ? <BaseImage
                          className={orderClass}
                          image={prod.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData}
                          alt={prod.title}
                          objectFit="contain"
                          imageType="gatsbyDynamic"
                        />
                        : <h2 className={orderClass}>{prod.title}</h2>
                      }
                    </BaseLink>
                  })}
                </div>
              })}
            </div>

            {pageContent.mainContentAlternatingView && <BaseLineSeparator />}

            {pageContent.mainContentAlternatingView?.map((c, idx) => {
              const alignment = idx % 2 === 0 ? 'right' : 'left';
              const headingLower = c.heading.toLowerCase();
              const scrollToId = `${hashScrollPrefix}${headingLower.replaceAll(' ', '-')}`;
              return <div key={idx} className="alternator" data-content-alignment={alignment}>
                <Element id={scrollToId} name={scrollToId} />
                <PageSectionContentOnImage
                  id={`ContentPageContentSection-${idx}`}
                  className={joinClassNames("FrontPageContentSection ContentPageContentSection", headingLower)}
                  contentPosition={alignment}
                  image={{
                    media: c.featuredImage,
                    altText: c.featuredImage?.altText,
                  }}
                  content={{
                    heading: c.heading,
                    body: c.body,
                    actionLink: c.actionLinkTag,
                    relatedSubproduct: c.relatedSubproduct,
                  }}
                />
              </div>
            }
            )}

            {pageContent.mainContentBlock && <BaseLineSeparator />}

            {pageContent.mainContentBlock && <div className="PageContentPage_Main_Block_Container">
              {pageContent.mainContentBlock.map((block, blockIdx) => {
                const headingLower = block.heading.toLowerCase();
                const scrollToId = `${hashScrollPrefix}${headingLower.replaceAll(' ', '-')}`;
                return <div key={blockIdx} className="PageContentPage_Main_Block_Single">
                  <Element id={scrollToId} name={scrollToId} />
                  <h2 className="PageContentPage_Main_Block_Single_Header">{block.heading}</h2>
                  <div className="PageContentPage_Main_Block__Single_SubBlocks">
                    {block.subBlocks.map((subBlock, subBlockIdx) => {
                      const headingLower = subBlock.heading.toLowerCase();
                      const scrollToId = `${hashScrollPrefix}${headingLower.replaceAll(' ', '-')}`;
                      return <div key={subBlockIdx} className="PageContentPage_Main_Block_Single_SubBlock">
                        <Element id={scrollToId} name={scrollToId} />
                        <h2 className="PageContentPage_Main_Block_Single_SubBlock_Header">{subBlock.heading}</h2>
                        <div className="PageContentPage_Main_Block_Single_SubBlock_Body" dangerouslySetInnerHTML={{ __html: sanitize(subBlock.body) }} />
                      </div>
                    })}
                  </div>
                </div>
              })}
            </div>}

          </main>

        </div>
      </section>

    </PageTemplate>
  );
};

export default PageContentPage;
