import { Observer } from 'mobx-react-lite';
import React from 'react';
import './BaseLineSeparator.scss';

type BaseLineSeparatorProps = {}

const BaseLineSeparator: React.FC<BaseLineSeparatorProps> = props => {
  return <hr className="BaseLineSeparator" />
}

export default BaseLineSeparator;